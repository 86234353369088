import React from "react"
import PropTypes from "prop-types"
import Logo from "../logo"

import styles from "./styles.module.css"

const Header = ({ siteTitle }) => (
  <header className={styles.header}>
    <div className={styles.logo}>
      <Logo />
    </div>
    <h1 className="visuallyhidden">{siteTitle}</h1>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

export default Header
